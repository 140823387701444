import { Route } from '@angular/router';
import { canCreateUserGuard } from '@lorient-emploi-front/signin';
import { BreadcrumbResolver } from './breadcrumb.resolver';

export const appRoutes: Route[] = [
  {
    path: 'bienvenue',
    pathMatch: 'full',
    loadComponent: () => import('@lorient-emploi-front/home').then((m) => m.HomeComponent),
  },
  {
    path: 'inscription',
    loadComponent: () => import('@lorient-emploi-front/signin').then((m) => m.SigninComponent),
    canActivate: [canCreateUserGuard],
    data: { breadcrumb: 'Inscription' },
  },
  {
    path: 'decouvrir',
    loadComponent: () => import('@lorient-emploi-front/discovery').then((m) => m.DiscoveryComponent),
    data: { breadcrumb: 'Découvrir' },
  },
  {
    path: 'recherche',
    loadComponent: () => import('@lorient-emploi-front/research').then((m) => m.ResearchComponent),
    data: { breadcrumb: 'Recherche' },
  },
  {
    path: 'agenda',
    loadComponent: () => import('@lorient-emploi-front/agenda').then((m) => m.AgendaComponent),
    data: { breadcrumb: 'Agenda' },
  },
  {
    path: 'showcase',
    loadComponent: () => import('@lorient-emploi-front/showcase').then((m) => m.ShowcaseComponent),
    data: { breadcrumb: 'Showcase' },
  },
  {
    path: 'carte',
    loadComponent: () => import('@lorient-emploi-front/map').then((m) => m.MapComponent),
    data: { 
      breadcrumb: 'La carte',
      breadcrumb_desktop_only: true,
      hideFooter: true
    },
  },
  {
    path: 'actualites',
    redirectTo: 'recherche',
    pathMatch: 'full'
  },
  {
    path: 'actualites',
    data: { breadcrumb: 'Actualités', resourceName: 'news' },
    children: [
      {
        path: ':id',
        loadComponent: () => import('@lorient-emploi-front/single').then((m) => m.SingleComponent),
        resolve: { breadcrumb: BreadcrumbResolver },
        data: { breadcrumbDynamic: true }
      },
    ],
  },
  {
    path: 'evenements',
    redirectTo: 'recherche',
    pathMatch: 'full'
  },
  {
    path: 'evenements',
    data: { breadcrumb: 'Évènements', resourceName: 'events' },
    children: [
      {
        path: ':id',
        loadComponent: () => import('@lorient-emploi-front/single').then((m) => m.SingleComponent),
        resolve: { breadcrumb: BreadcrumbResolver },
        data: { breadcrumbDynamic: true }
      },
    ],
  },
  {
    path: 'metiers',
    redirectTo: 'recherche',
    pathMatch: 'full'
  },
  {
    path: 'metiers',
    data: { breadcrumb: 'Métiers', resourceName: 'jobs' },
    children: [
      {
        path: ':id',
        loadComponent: () => import('@lorient-emploi-front/single').then((m) => m.SingleComponent),
        resolve: { breadcrumb: BreadcrumbResolver },
        data: { breadcrumbDynamic: true }
      },
    ],
  },
  {
    path: 'metier-entreprise',
    data: { breadcrumb: 'Fichier métier', resourceName: 'occupations' },
    children: [
      {
        path: ':id',
        loadComponent: () => import('@lorient-emploi-front/occupation').then((m) => m.OccupationComponent),
        resolve: { breadcrumb: BreadcrumbResolver },
        data: { breadcrumbDynamic: true }
      },
    ],
  },
  {
    path: 'secteurs-activites',
    redirectTo: 'recherche',
    pathMatch: 'full'
  },
  {
    path: 'secteurs-activites',
    data: { breadcrumb: 'Secteurs d\'activités', resourceName: 'sectors' },
    children: [
      {
        path: ':id',
        loadComponent: () => import('@lorient-emploi-front/single').then((m) => m.SingleComponent),
        resolve: { breadcrumb: BreadcrumbResolver },
        data: { breadcrumbDynamic: true }
      },
    ],
  },
  {
    path: 'lieux',
    redirectTo: 'recherche',
    pathMatch: 'full'
  },
  {
    path: 'lieux',
    data: { breadcrumb: 'Lieux', resourceName: 'locations' },
    children: [
      {
        path: ':id',
        loadComponent: () => import('@lorient-emploi-front/single').then((m) => m.SingleComponent),
        resolve: { breadcrumb: BreadcrumbResolver },
        data: { breadcrumbDynamic: true }
      },
    ],
  },
  {
    path: 'organismes',
    redirectTo: 'recherche',
    pathMatch: 'full'
  },
  {
    path: 'organismes',
    data: { breadcrumb: 'Organismes', resourceName: 'organizations' },
    children: [
      {
        path: ':id',
        loadComponent: () => import('@lorient-emploi-front/single').then((m) => m.SingleComponent),
        resolve: { breadcrumb: BreadcrumbResolver },
        data: { breadcrumbDynamic: true }
      },
    ],
  },
  {
    path: 'entreprises',
    redirectTo: 'recherche',
    pathMatch: 'full'
  },
  {
    path: 'entreprises',
    data: { breadcrumb: 'Entreprises', resourceName: 'companies' },
    children: [
      {
        path: ':id',
        loadComponent: () => import('@lorient-emploi-front/single').then((m) => m.SingleComponent),
        resolve: { breadcrumb: BreadcrumbResolver },
        data: { breadcrumbDynamic: true }
      },
    ],
  },
  {
    path: 'questionnaire',
    loadComponent: () => import('@lorient-emploi-front/quizz').then((m) => m.QuizzComponent),
    data: { breadcrumb: 'Questionnaire' },
    children: [
      {
        path: '',
        loadComponent: () => import('@lorient-emploi-front/quizz').then((m) => m.QuestionsComponent),
      },
      {
        path: 'resultat',
        loadComponent: () => import('@lorient-emploi-front/quizz').then((m) => m.ResultsComponent),
        data: { breadcrumb: 'Résultat du questionnaire' }
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'bienvenue',
    pathMatch: 'full'
  }
];
