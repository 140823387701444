<footer class="bg-white pt-72 px-24 pb-56 ">
    <div class="md:max-w-1100 md:flex justify-between m-auto">
        <div class="md:max-w-130">
            <img class="mb-24" src="assets/images/logo.svg" alt="MonFuturPro">
        
            <p class="text-gray-300 text-16 mb-48">
                Le nouvel accélérateur d'avenir du Pays de Lorient-Quimperlé.
            </p>
        </div>
    
        @for (plan of sitePlans; track $index) {
            <div class="mb-48">
                <p class="mb-24 font-semibold text-14 text-black">{{ plan.name }}</p>
    
                @for (link of plan.links; track $index) {
                    <a class="px-8 py-12 block font-semibold text-14 text-gray-300" [routerLink]="link.href">{{ link.name }}</a>
                }
            </div>
    
        }
    </div>

    <div class="md:max-w-1100 m-auto">
        <div class="mb-24 font-semibold text-14 text-gray-300">
            Conçue pour créer des liens durables entre les jeunes et les entreprises, le projet est porté par : 
            <div class="flex flex-col gap-10 md:flex-row items-center gap-10 text-14 text-gray-300 mt-10">
                <div class="flex items-center justify-center mb-5">
                    <img class="w-auto h-50" src="assets/images/footer/ml.jpg">
                </div>
                Mission Locale du Pays de Lorient, en collaboration avec
                <div class="flex items-center justify-center mb-5">
                    <img class="w-auto h-50" src="assets/images/footer/ml-cornouaille.png">
                </div>
                Mission Locale Pays de Cornouaille
            </div>
               
        </div>
        <div class="mb-24 font-semibold text-14 text-gray-300">
            Soutenue par : 
            <div class="flex flex-col gap-10 md:flex-row items-center gap-10 mt-10">
                <div class="text-gray-300 text-12">
                    <div class="flex items-center justify-center mb-5">
                        <img class="w-auto h-50" src="assets/images/footer/ue.png">
                    </div> 
                    L’Union Européenne,
                </div>
                <div class="text-gray-300 text-12">
                    <div class="flex items-center justify-center mb-5">
                        <img class="w-auto h-50" src="assets/images/footer/lorient-agglo.jpg">
                    </div> 
                    Lorient Agglomération,
                </div>
                <div class="text-gray-300 text-12">
                    <div class="flex items-center justify-center mb-5">
                        <img class="w-auto h-50" src="assets/images/footer/quimperle.png">
                    </div> 
                    Quimperlé Communauté,
                </div>
                <div class="text-gray-300 text-12">
                    <div class="flex items-center justify-center mb-5">
                        <img class="w-auto h-50" src="assets/images/footer/bbo.png">
                    </div> 
                    BBO Communauté,
                </div>
                <div class="text-gray-300 text-12">
                    <div class="flex items-center justify-center mb-5">
                        <img class="w-auto h-50" src="assets/images/footer/bzh.jpg">
                    </div> 
                    Région Bretagne,
                </div>
                <div class="text-gray-300 text-12">
                    <div class="flex items-center justify-center mb-5">
                        <img class="w-auto h-50" src="assets/images/footer/les-ml.png">
                    </div> 
                    Union nationale des Missions Locales. 
                </div>
            </div>
        </div>
    </div>

    <div class="m-auto md:max-w-1100 w-full border-b-1 border-gray-200 mb-32"></div>

    <div class="m-auto md:max-w-1100 flex justify-between">
        <div class="">
            <p class="text-gray-300">(Copyright) 2024</p>
            <p class="text-gray-300">Mission Locale Réseaux pour l'emploi du Pays de Lorient</p>
        </div>

        <div class="flex gap-16">
            <a href="https://www.facebook.com/Mission.Locale.Pays.Lorient/" target="_blank"><img class="w-48" src="assets/images/facebook.svg" alt="facebook"></a>
            <a href="https://www.instagram.com/missionlocalelorient/" target="_blank"><img class="w-48" src="assets/images/instagram.svg" alt="instagram"></a>
            <a href="https://fr.linkedin.com/company/mission-locale-r-seaux-pour-l-emploi-du-pays-de-lorient" target="_blank"><img class="w-48" src="assets/images/linkedin.svg" alt="linkedin"></a>
        </div>
    </div>
</footer>
