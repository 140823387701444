import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbResolver implements Resolve<string | undefined> {
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string | undefined> {
    // Retourne l'ID de la route
    return of(route.paramMap.get('id')?.replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase()));
  }
}